<template>

  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
          v-on:keyup.enter="searchOnEnter"
          v-on:blur="searchOnEnter"
          append-icon="mdi-magnify"
          hint="No Invoice, Nama Customer, No HP(press ENTER to search)"
          label="Search"
      ></v-text-field>
      <v-btn
          @click="showFilterDialog"
          icon fab
          color="blue"
          small
          class="ml-2"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>

    </v-card-title>

    <v-expand-transition>
     <v-card class="mx-2 mb-1 elevation-0 color-top-left-gradient-3" v-show="showFilter===true">
      <v-card-text>

        <v-row >
          <v-col
              cols="12"
              sm="6"
              md="3"
          >
            <v-autocomplete
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Distributor"
                multiple
                hide-details
            ></v-autocomplete>
          </v-col>
          <v-col
              cols="12"
              sm="6"
              md="4"
          >
            <v-autocomplete
                v-model="filterFsalesmans"
                :items="itemsFSalesman"
                item-value="id"
                item-text="spname"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Salesman/CS"
                multiple
                hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-flex class="d-flex justify-end">
              <span class="subtitle-2 mr-2">
                Tgl Order
              </span>
              <DateRangePicker
                  v-model="datePickerRangeOrder"
                  :locale-data="dprLocale"
                  @update="changeDatePickerRange"
                  :autoApply="dprAutoApply"
                  :showDropdowns="dprShowDropdowns"
              ></DateRangePicker>
            </v-flex>
          </v-col>

          <v-col cols="6" sm="3" md="1">
            <v-btn color="primary" small @click="runTerapkan">
              Terapkan
              <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    </v-expand-transition>

    <div>
      <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="table"
          transition="scale-transition"
      >
      </v-skeleton-loader>

      <v-data-table
          v-if="! loading"
          v-model="selectedItems"
          :single-select="!multiSelect"
          :show-select="multiSelect"
          :headers="headers"
          :items="feSaleshsFiltered"
          :page.sync="currentPage"
          :items-per-page="pageSize"
          hide-default-footer
          class="elevation-0"
          @page-count="totalTablePages =totalPaginationPages "
          align="start"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <v-switch
                v-model="multiSelect"
                :label="multiSelect?'Multi Select':'Single Select'"
                x-small
                class="pa-3"
            ></v-switch>
            <v-btn
                fab
                dark
                color="red accent-4"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="deleteDialogMultiShow"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-col
                cols="3"
                md="3"
                sm="3"
            >
              <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Items per page"
              ></v-select>
            </v-col>

            <v-spacer></v-spacer>
            <v-menu offset-y style="align-items: start"
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                    class="mr-2"
                    dark
                    small
                    color="indigo"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="white" class="mr-2" small>mdi-sync</v-icon>
                  Sync data
                </v-btn>

              </template>

              <v-list color="grey lighten-4" >
                <v-list-item>
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="showConfirmSyncKino"
                  >
                    <v-icon color="blue" class="mr-2" small>mdi-sync</v-icon>
                    Sinkron Kino
                  </v-btn>
                </v-list-item>
              </v-list>

            </v-menu>

            <v-menu offset-y style="align-items: start"
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                    class="ml-2"
                    fab
                    dark
                    x-small
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-view-grid</v-icon>
                </v-btn>

              </template>

              <v-list color="grey lighten-4" >

                <v-divider></v-divider>
              </v-list>

            </v-menu>

          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{ (index + ((currentPage-1)*pageSize) +1 )}}
        </template>

        <template v-slot:[`item.orderno`]="{ item }">
          <div>
            <div class="caption font-weight-bold">
              {{item.orderno}}
            </div>
            <div class="caption"  v-if="item.orderno">
              {{ dateFormattedDatefnsShort(item.orderDate) }}
            </div>

          </div>
        </template>
        <template v-slot:[`item.invoiceno`]="{ item }">
          <div>
            <div class="caption font-weight-bold" v-if="item.invoiceno">
              {{item.invoiceno}}
            </div>
            <div class="caption"  v-if="item.invoiceno">
              {{ dateFormattedDatefnsShort(item.invoiceDate) }}
            </div>
            <div class="caption grey--text" v-if="lookupFSalesman(item.fsalesmanBean)">
              #{{ shrinkText(lookupFSalesman(item.fsalesmanBean).spname, 6) }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.feSalesdItems`]="{ item }">
          <div>
            <div >

              <v-tooltip bottom color="rgba(255, 255, 255, 0)">
                <template v-slot:activator="{ on, attrs }">
                  <div v-if="item.listFtSalesdItems">
                    <div class="caption font-weight-bold deep-orange--text" v-bind="attrs" v-on="on" >Items : {{item.listFtSalesdItems.length }}</div>
                  </div>
                  <div class="caption grey--text" >
                    #{{ lookupFWarehouse(item.fwarehouseBean).kode1 }}
                  </div>
                </template>

                <v-card width="500" class="pa-2 rounded" elevation="8"  v-if="item.listFtSalesdItems">
                  <v-data-table
                      dense
                      :headers="headersFtSalesdItems"
                      :items="item.listFtSalesdItems"
                      :items-per-page="item.listFtSalesdItems.length"
                      class="elevation-0"
                      hide-default-footer
                  >
                    <template v-slot:[`item.pcode`]="{ item }">
                      <div>
                        <div class="text-caption">{{ lookupFMaterial(item.fmaterialBean).pcode}} </div>
                        <div class="text-caption grey--text" v-if="lookupFMaterial(item.fmaterialBean).principalCode">#{{ lookupFMaterial(item.fmaterialBean).principalCode}} </div>
                      </div>
                    </template>
                    <template v-slot:[`item.fmaterialBean`]="{ item }">
                      <div>
                        <span class="text-caption">{{ lookupFMaterial(item.fmaterialBean).pname}} </span>
                      </div>
                    </template>
                    <template v-slot:[`item.sprice`]="{ item }">
                      <div>
                        <div class="text-caption">{{ formattedCurrencyValue('', item.sprice)}}</div>
                      </div>
                    </template>
                  </v-data-table>
                </v-card>

              </v-tooltip>

            </div>
          </div>
        </template>

        <template v-slot:[`item.fcustomerBean`]="{ item }">
          <div>
            <div>
              <span class="text-subtitle-1">
                {{lookupFCustomer(item.fcustomerBean).custname}}
              </span>
                <span class="caption grey--text ml-1">
                #{{lookupFCustomer(item.fcustomerBean).custno}}
              </span>
            </div>

            <div class="caption">
              <v-icon x-small color="warning">mdi-map-marker</v-icon> {{lookupFCustomer(item.fcustomerBean).address1}}
            </div>
          </div>
        </template>

        <template v-slot:[`item.amountRp`]="{ item }">
          <div>
            <div v-if="(item.amountAfterDiscPlusRpAfterPpn - (item.amountRp+item.amountPpnRp)) >0">
              <span class="caption grey--text">Disc:</span>
              <span class="grey--text text--darken-1">
                {{ formattedCurrencyValue('',item.amountAfterDiscPlusRpAfterPpn - (item.amountRp+item.amountPpnRp)) }}
              </span>
            </div>
            <div class="subtitle-1 font-weight-bold blue--text">
              {{ formattedCurrencyValue('', item.amountAfterDiscPlusRpAfterPpn_FG) }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.statusActive`]="{ item }">
          <v-chip
              :color="getColorStatusActive(item.statusActive)"
              dark
              x-small
          >
            {{ item.statusActive===true?'Aktif': 'Non-Aktif' }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div  class="text-caption small font-weight-light grey--text" style="line-height: 1.1;">
            <div class="caption grey--text text--darken-3" v-if="lookupFDivision(item.fdivisionBean)">{{ lookupFDivision(item.fdivisionBean).kode1 }}</div>
            <div>{{ dateFormattedDatefnsWithTime(item.created) }}</div>
            <span>{{ dateFormattedDatefnsWithTimeShort(item.modified) }}</span>
            <span class="ml-1">{{ item.modifiedBy }} </span>
          </div>

          <div v-if="false">
            <v-btn
                @click="showDialogEdit(item)"
                icon
                :disabled="multiSelect"
            >
              <v-icon
                  small
                  color="warning"
              >
                mdi-pencil
              </v-icon>
            </v-btn>

            <v-btn
                @click="deleteDialogShow(item)"
                icon
                :disabled="multiSelect || item.deliveryNumber !== '' "
            >
              <v-icon
                  small
                  color="red accent-4"
              >
                mdi-delete
              </v-icon>
            </v-btn>


          </div>
        </template>

      </v-data-table>
    </div>

    <v-container>
      <v-row justify="end" align="center">

        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <SyncConfirmDialog
        ref="refSyncConfirmDialog"
    ></SyncConfirmDialog>


    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
        v-model="loadingSync"
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import FtSaleshService from '@/services/apiservices/ft-salesh-service';
import FileService from "@/services/apiservices/file-service"

import DeleteConfirmDialog from "../../../components/utils/DeleteConfirmDialog";

import FormMode from "@/models/form-mode";
import FtSalesh from '@/models/ft-salesh'

import EStatusPengiriman, {EStatusPengirimans} from "@/models/e-status-pengiriman";

import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import ERole from "@/models/e-role";

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import {
  dateFormattedDatefns,
  dateFormattedDatefnsShort,
  dateFormattedDatefnsWithTime,
  dateFormattedDatefnsWithTimeShort
} from "@/utils/date-utils"
import FTaxService from "@/services/apiservices/f-tax-service";
import FtSalesdItemsService from "@/services/apiservices/ft-salesd-items-service";
import MyConfirmDialog from "@/components/cloud-mobile/MyConfirmDialog.vue";

export default {
  components: {
    SyncConfirmDialog: MyConfirmDialog, DateRangePicker, DeleteConfirmDialog

  },
  props:{
  },
  data () {
    return {
      loading: false,
      loadingSync: false,
      title: 'ORDER & INVOICE',

      // date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      filterOrderDateFrom: '',
      menuDate2: false,
      filterOrderDateTo: '',

      datePickerRangeOrder: {
        startDate: function(d){ d.setDate(d.getDate()-30); return d}(new Date),
        endDate: new Date(),
      },

      datePickerRangeInv: {
        startDate: function(d){ d.setDate(d.getDate()-2); return d}(new Date),
        endDate: new Date(),
      },

      dprLocale: {
        direction: 'ltr', //direction of text
        format: 'dd/mm/yyyy',
        separator: ' - ', //separator between the two ranges
        applyLabel: 'Terapkan',
        cancelLabel: 'Batal',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        // daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        // monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      dprAutoApply:true,
      dprShowDropdowns:true,


      showFilter: false,
      filterFdivisions: [],
      filterFsalesmans: [],
      filterFstores: [],
      filterFexpedisies:[],
      filterFmaterials: [],
      filterEstatusPengiriman: [],
      filterFwarehouses: [],

      filterByGroupMaterialOrdered: [],
      itemsGroupMaterialOrdered:[],

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 20,
      pageSizes: [10, 20, 40, 75, 150, 500, 1000, 2500, 5000],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'No.Order', value: 'orderno', width:'12%' },
        { text: 'Invoice', value: 'invoiceno', width:'12%' },
        { text: 'Customer', value: 'fcustomerBean', width: '25%' },
        { text: 'Items', value: 'feSalesdItems', width: '15%' },
        { text: 'Total', value: 'amountRp', width: '12%'  },
        { text: 'Acts', value: 'actions', width: '12%', align:'start', sortable: false },
      ],
      headersFtSalesdItems: [
        { text: 'No', value: 'noUrut', width:'8%' },
        { text: 'Pcode', value: 'pcode', width:'15%' },
        { text: 'Produk', value: 'fmaterialBean', width:'60%' },
        { text: 'Qty pcs', value: 'qty', width:'17%', sortable:false },
        { text: 'Net Sprice', value: 'sprice', width:'17%', sortable:false },
      ],
      // formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      feSaleshs:[
          new FtSalesh(1, "")
      ],
      itemsFCustomer: [],
      itemsFMaterial: [],
      itemsEStatusPengiriman: EStatusPengirimans,

      boldClass: 'font-weight-bold',
      redClass: 'red--text'
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.runExtendedFilter()
          }

        }


      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage===1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)

          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.runExtendedFilter()
          }

        }

        // console.log("page size = " + value + " >> " + this.totalPages)

      }
    },
    multiSelect:function (value){
      if (value===false){
        this.selectedItems = []
      }
    }

  },

  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },

    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },


    feSaleshsFiltered(){
      return this.feSaleshs
    },
    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },

    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },
    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          listFSalesman.push(item)
        })
      }

      return listFSalesman
    },


  },

  methods: {
    runTerapkan(){
      this.currentPage=1
      this.runExtendedFilter()
    },
    showConfirmSyncKino(){
      this.$refs.refSyncConfirmDialog.showDialog("Apakah anda yakin untuk melakukan sinkron data Kino?")
    },

    shrinkText(text, maxLength){
      if (text.length > maxLength){
        return text.substring(0, maxLength) + ''
      }else {
        return text
      }
    },

    showFilterDialog(){
      this.showFilter = !this.showFilter

      const itemPayload = Object
      itemPayload.orderDateFrom =  this.datePickerRangeInv.startDate
      itemPayload.orderDateTo =  this.datePickerRangeInv.endDate
      itemPayload.invoiceDateFrom =  this.datePickerRangeInv.startDate
      itemPayload.invoiceDateTo =  this.datePickerRangeInv.endDate


    },

    changeDatePickerRange(){
      // console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
    },

    runExtendedFilter(){
      const feSaleshFilter = new FtSaleshFilter()

      feSaleshFilter.fdivisionIds =  this.filterFdivisions //dilakukan pada back-end untuk security

      if (this.filterFsalesmans.length >0){
        feSaleshFilter.fsalesmanIds = this.filterFsalesmans
      }else {
        feSaleshFilter.fsalesmanIds = this.itemsFSalesman.map( item => item.id)
      }

      feSaleshFilter.orderDateFrom =  this.datePickerRangeOrder.startDate
      feSaleshFilter.orderDateTo =  this.datePickerRangeOrder.endDate
      feSaleshFilter.invoiceDateFrom =  this.datePickerRangeInv.startDate
      feSaleshFilter.invoiceDateTo =  this.datePickerRangeInv.endDate

      feSaleshFilter.pageNo = this.currentPage
      feSaleshFilter.pageSize = this.pageSize
      feSaleshFilter.sortBy = "id"
      feSaleshFilter.order = "DESC"
      feSaleshFilter.search = this.search

      this.loadingSync = true
      FtSaleshService.getAllFtSaleshContainingExt(feSaleshFilter).then(
          response =>{
            const { items, totalPages, listFCustomer, listFMaterial} = response.data
            this.feSaleshs = items
            this.totalPaginationPages = totalPages

            this.itemsFCustomer = listFCustomer
            this.itemsFMaterial = listFMaterial

            // console.log(JSON.stringify(this.itemsFMaterial, null, 2))
            this.loadingSync = false
          },
          error =>{
            this.feSaleshs = []
            console.log(error)
          }
      )

    },

    searchOnEnter(event){
      if (this.search !== event.target.value) {
        this.currentPage = 1
        this.search = event.target.value

        this.runExtendedFilter()

      }

    },

    fetchParent(){
      this.$store.dispatch('data/loadFDivisionByOrgLevel')

      let fsalesmanBean = 0
      if (this.currentUser.roles !== undefined){
        if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
          fsalesmanBean = this.currentUser.salesmanOf
        }
      }

      /**
       * Pada suatu saat akan tidak mungkin untuk load semua Fcustomer
       */
      const payload = {'fsalesmanBean': fsalesmanBean}
      payload.toString()

      this.$store.dispatch('data/loadFWarehouse')
      this.$store.dispatch('data/loadFSalesman')
      this.$store.dispatch('data/loadFMaterial')

      FTaxService.getAllFTax().then(
          response =>{
            this.$store.dispatch('sales/reloadItemsFTax', response.data)
          }
      )


    },


    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.feSaleshsFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
    },

    eventEditFormDialog1() {
    },
    eventCreateFormDialog1() {
    },

    eventCloseFormDialog1(){

      if (this.showFilter){
        this.runExtendedFilter()
      }else {
        this.runExtendedFilter()
      }
    },
    deleteDialogShow (item) {
      this.itemSelectedIndex = this.feSaleshsFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      let title = undefined
      let message = `${item.orderno} >> ${item.destName !==undefined? item.destName: '' }, ${item.destCity1 !==undefined? item.destCity1: '' } `
      if (item.invoiceno !== ''){
        title = 'SUDAH TERBIT INVOCE, YAKIN BATAL INVOICE?'
        message = `${item.orderno} | INV: ${item.orderno} >> ${item.destName !==undefined? item.destName: '' }, ${item.destCity1 !==undefined? item.destCity1: '' } `
      }

      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, message, title)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.feSaleshs[this.itemSelectedIndex]
      if (deletedItem.invoiceno === ''){
        FtSaleshService.deleteFtSaleshWithChildren(deletedItem.id).then(
            () => {
              this.feSaleshs.splice(this.itemSelectedIndex, 1)
              this.$refs.refDeleteConfirmDialog.setDialogState(false)

            },
            error => {
              console.log(error)
              this.snackBarMesage = 'Gagal hapus (Sudah ada proses)'
              this.snackbar = true
              this.$refs.refDeleteConfirmDialog.setDialogState(false)
            }
        )
      }else {
        let updateItem = deletedItem
        updateItem.statusPengiriman = EStatusPengiriman.CANCELED
        FtSalesdItemsService.updateFtSalesdItemsCancelQtyByParent(updateItem).then(
            () => {

              FtSaleshService.updateFtSalesh(updateItem).then(
                  () => {
                    this.$refs.refDeleteConfirmDialog.setDialogState(false)
                  },
                  error => {
                    console.log(error)
                    this.snackBarMesage = 'Gagal Batal Order'
                    this.snackbar = true
                    this.$refs.refDeleteConfirmDialog.setDialogState(false)
                  }
              )

            }
        )


      }

    },
    deleteItemConfirmedMultiSelect(items){

      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FtSaleshService.deleteAllFtSalesh(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.feSaleshsFiltered.indexOf(items[i])
                      this.feSaleshs.splice(index, 1)
                    }
                    // if (response.data.length > 0) {
                    //   this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                    //   this.snackbar = true
                    // }

                    // console.log("Undeleted Items: " + response.data + " = " + items[i].id)

                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )


      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })

    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return undefined
      }
    },

    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id===fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)

      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return require('@/assets/images/no_image_available.jpeg')
    },

    formattedCurrencyValue(prefix, value){
      const roundedNumber = Math.round(value);
      return prefix + roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    dateFormattedDatefns(value){
      return dateFormattedDatefns(value)
    },
    dateFormattedDatefnsShort(value){
      return dateFormattedDatefnsShort(value)
    },
    dateFormattedDatefnsWithTime(value){
      return dateFormattedDatefnsWithTime(value)
    },
    dateFormattedDatefnsWithTimeShort(value){
      return dateFormattedDatefnsWithTimeShort(value)
    },

  },

  mounted() {

    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()


      if (this.$route.params.statusPengiriman !== undefined){

        const dateFromLong = this.$route.params.dateFrom
        const dateToLong = this.$route.params.dateTo

        this.showFilter = true
        this.datePickerRangeInv.startDate = new Date(parseInt(dateFromLong))
        this.datePickerRangeInv.endDate = new Date(parseInt(dateToLong))
        this.datePickerRangeInv.startDate = new Date(parseInt(dateFromLong))
        this.datePickerRangeInv.endDate = new Date(parseInt(dateToLong))

        this.runExtendedFilter()
      }else {
        this.runExtendedFilter()
      }

    }


  }

}
</script>

<style scoped>

</style>
