<template>
  <v-dialog
    v-model="dialogShow"
    :max-width="syncConfirmOptions.width"
    :style="{ zIndex: syncConfirmOptions.zIndex }"
    @keydown.esc="dialogShow = false"
  >
    <v-card>
      <v-toolbar dark :color="syncConfirmOptions.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold white--text">
          {{ syncConfirmOptions.title }}
        </v-toolbar-title>
      </v-toolbar>

      <p align="center" class="mt-2 mx-2">
        {{ syncConfirmOptions.message1 }}
        <br/>
        {{
          syncConfirmOptions.message2
        }}
      </p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" outlined text @click="dialogShow = false"
          >Cancel</v-btn
        >
        <v-btn color="green darken-1" outlined text @click="confirmSync"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>
export default {
  name: "MyConfirmDialog",
  data() {
    return {
      dialogShow: false,
      syncConfirmOptions: {
        title: "Konfirmasi Dialog",
        message1: "",
        message2: "",
        color: "indigo darken-1",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  props: {
    dialogName: String
  },
  computed: {},
  methods: {
    confirmSync: function () {

      this.$emit("eventFromMyConfirmDialog", this.dialogName);

    },
    showDialog(message1, message2) {
      this.syncConfirmOptions.message1 =
        message1 !== undefined ? message1 : "";
      this.syncConfirmOptions.message2 =
        message2 !== undefined ? message2 : "";
      this.dialogShow = !this.dialogShow;
    },
    setDialogState(value) {
      console.log(value);
      this.dialogShow = false;
    },
  },
};
</script>
    
    <style scoped>
</style>