import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtSaleshService {
    getApiUrl() {
        return API_URL
    }

    getAllFtSalesh(){
        return axios.get(API_URL + `getAllFtSalesh`, { headers: authHeader() });
    }

    getAllFtSaleshContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFtSaleshContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFtSaleshContainingLight(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFtSaleshContainingLight?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }


    getAllFtSaleshByIds(itemIds){
        return axios.post(API_URL + `getAllFtSaleshByIds`, itemIds, { headers: authHeader() });
    }
    getAllFtSalesDetailByIds(itemIds){
        return axios.post(API_URL + `getAllFtSalesDetailByIds`, itemIds, { headers: authHeader() });
    }

    getPostAllFtSaleshByOrderno(data){
        return axios.post(API_URL + `getPostAllFtSaleshByOrderno`, data, { headers: authHeader() });
    }

    getAllFtSaleshContainingExt(item){
        // console.log(JSON.stringify(item))
        return axios.post(API_URL + `getAllFtSaleshContainingExt`, item, { headers: authHeader() });
    }
    getAllFtSaleshContainingExtLight(item){
        console.log('getAllFtSaleshContainingExtLight')
        return axios.post(API_URL + `getAllFtSaleshContainingExtLight`, item, { headers: authHeader() });
    }

    getAllFtSaleshContainingAndRemarkInExt(item){
        // console.log(`${JSON.stringify(item)}`)
        return axios.post(API_URL + `getAllFtSaleshContainingAndRemarkInExt`, item, { headers: authHeader() });
    }

    getAllFtSaleshContainingDesSimple(item){
        // console.log(JSON.stringify(item))
        return axios.post(API_URL + `getAllFtSaleshContainingDesSimple`, item, { headers: authHeader() });
    }


    getAllFtSaleshContainingExtSummary(item){
        return axios.post(API_URL + `getAllFtSaleshContainingExtSummary`, item, { headers: authHeader() });
    }

    postReportJasperPrintPos(item){
        return axios.post(API_URL + `reports/jasper/print_pos`, item, { headers: authHeader(), responseType:'blob' });
    }

    postReportJasperResiJnt(item){
        return axios.post(API_URL + `reports/jasper/resi_jnt`, item, { headers: authHeader(), responseType:'blob' });
    }
    postReportJasperResiJne(item){
        return axios.post(API_URL + `reports/jasper/resi_jne`, item, { headers: authHeader(), responseType:'blob' });
    }
    postReportJasperResiIdExpress(item){
        return axios.post(API_URL + `reports/jasper/resi_id_express`, item, { headers: authHeader(), responseType:'blob' });
    }
    postReportJasperResiSiCepat(item){
        return axios.post(API_URL + `reports/jasper/resi_si_cepat`, item, { headers: authHeader(), responseType:'blob' });
    }


    getFtSaleshById(id){
        return axios.get(API_URL + `getFtSaleshById/${id}`, { headers: authHeader() });
    }
    updateFtSalesh(item){
        // if(item.destName.includes("Angga")){
        //     console.log(JSON.stringify(item))
        // }
        return axios.put(API_URL + `updateFtSalesh/${item.id}`, item, {headers: authHeader()})
    }
    updateFtSaleshDeliveryStatus(item){
        return axios.put(API_URL + `updateFtSaleshDeliveryStatus/${item.id}`, item, {headers: authHeader()})
    }
    updateFtSaleshDeliveryStatusWithTime(item){
        return axios.put(API_URL + `updateFtSaleshDeliveryStatusWithTime/${item.id}`, item, {headers: authHeader()})
    }
    updateFtSaleshCreateInvoiceNo(item){
        return axios.put(API_URL + `updateFtSaleshCreateInvoiceNo/${item.id}`, item, {headers: authHeader()})
    }
    updateFtSaleshProcess(item, fwarehouseBean, fexpedisiBean){
        return axios.put(API_URL + `updateFtSaleshProcess/${item.id}/${fwarehouseBean}/${fexpedisiBean}`, item, {headers: authHeader()})
    }
    updateFtSaleshCancelAwb(item, fwarehouseBean, fexpedisiBean){
        return axios.put(API_URL + `updateFtSaleshCancelAwb/${item.id}/${fwarehouseBean}/${fexpedisiBean}`, item, {headers: authHeader()})
    }

    createFtSalesh(item){
        // console.log('Call Create New ftSalesh')
        return axios.post(API_URL + `createFtSalesh`, item, {headers: authHeader()})
    }
    deleteFtSalesh(id){
        return axios.delete(API_URL + `deleteFtSalesh/${id}`, {
            headers: authHeader()
        });
    }
    deleteFtSaleshWithChildren(id){
        return axios.delete(API_URL + `deleteFtSaleshWithChildren/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFtSalesh(itemIds){
        return axios.delete(API_URL + `deleteAllFtSalesh`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtSaleshService()
